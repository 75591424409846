import { asyncScheduler, BehaviorSubject, of, scheduled } from 'rxjs';
import { UserAccountService } from '../services/user-account.service';
import { TEST_ACCOUNTS, TEST_GET_JOURNEY, TEST_USER, TEST_JOURNEY_GETALL } from './calls.mock';
import { Journey } from '../models/journey.model';
import { AgentType } from '../enums/agents.enum';

export class TEST_JOURNEY_SERVICE {
  journeyList = TEST_JOURNEY_GETALL;
  get journey(): Journey {
    return this.onChangeJourney.value;
  }
  onChangeJourney = new BehaviorSubject(TEST_JOURNEY_GETALL[0]);
  get journey$() {
    return this.onChangeJourney.asObservable();
  }
  getJourneysList = () => scheduled(of(TEST_JOURNEY_GETALL), asyncScheduler);
  createJourney = () => scheduled(of(TEST_GET_JOURNEY) as any, asyncScheduler);
  editJourney = () => scheduled(of(true), asyncScheduler);
  deleteJourney = () => scheduled(of(true), asyncScheduler);
  setSessionJourney(journeyId: number) {
    const journey = this.journeyList.find((j) => j.id === journeyId) || this.journeyList[0];
    this.onChangeJourney.next(journey);
  }
  isProductConfigured(
    configuration: Array<Partial<Journey>> | Partial<Journey> | Array<keyof typeof AgentType> | keyof typeof AgentType,
    journeyId?: number | null
  ): boolean {
    const journey: Journey = journeyId ? this.journeyList.find((item) => item.id === journeyId) : this.journey;
    if (!Array.isArray(configuration)) configuration = [<Partial<Journey>>configuration];
    if (typeof configuration[0] === 'string') configuration = (configuration as Array<any>).map((item) => ({ type: item }));
    return configuration.some((restriction) =>
      Object.keys(restriction)
        .filter((key) => typeof restriction[key] === 'boolean' || typeof restriction[key] === 'string')
     .every((key) => restriction[key] === journey[key])
    );
  }
}

export const TEST_USER_ACCOUNT_SERVICE: Partial<UserAccountService> = {
  user: TEST_USER,
  userAccounts: TEST_ACCOUNTS,
  sessionLoaded: true,
  get sessionAccount$() {
    return new BehaviorSubject(this.journey).asObservable();
  },
  initSession: () => scheduled(of(true), asyncScheduler),
  changeAccountUser: () => scheduled(of(true), asyncScheduler),
  isAuthorized: function (restrictedPermission: Array<string> | string): boolean {
    if (!Array.isArray(restrictedPermission)) restrictedPermission = [restrictedPermission];

    return restrictedPermission.some((permission) => this.user.permissions.includes(permission));
  }
};
