export type ProductCodeType =
  | 'AM'
  | 'BQ'
  | 'D'
  | 'DLP'
  | 'E'
  | 'EC'
  | 'EH'
  | 'NL'
  | 'Q'
  | 'R'
  | 'RL'
  | 'T'
  | 'DT'
  | 'DP'
  | 'ZD'
  | 'SF'
  | 'SFS'
  | 'CE'
  | 'SG'
  | 'TW'
  | 'T_B'
  | 'T_C'
  | 'T_S'
  | 'M_C'
  | 'M_CL'
  | 'M_A'
  | 'M_I'
  | 'C_F'
  | 'C_V'
  | 'C_W'
  | 'C_T'
  | 'C_L'
  | 'C_G'
  | 'C_TG'
  | 'C_TE'
  | 'RS';
