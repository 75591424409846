import { AgentType } from "../enums/agents.enum";

export interface Agent {
  accountId: number;
  agentId: string;
  agentType: AgentType;
  id: number;
  locationId: string;
  multiLang: boolean;
  name: string;
  projectId: string;
  supportedLang: string;
}