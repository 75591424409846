import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { CommonService } from '@shared';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  menuSubscription: Subscription
  stretchMenu: boolean;
  openChat: boolean;

  constructor(private commonService: CommonService) { }

  ngOnInit() {
    this.menuSubscription = this.commonService.onStretchMenu().subscribe((strechStatus: boolean) => {
      this.stretchMenu = strechStatus;
    });
  }

  ngOnDestroy() {
    this.menuSubscription.unsubscribe();
  }

  toggleChat($event) {
    this.openChat = $event;
  }

}
