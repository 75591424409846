<div class="console_modal chat_settings_dialog">
    <div class="modal-header">
        <h4 class="modal-title">User data configuration</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body" [formGroup]="settingsForm">
        <div class="row">
            <div class="d-flex col-12">
                <div class="col-10">
                <ul ngbNav #chatNav="ngbNav" class="nav-tabs align-items-center">
                    <li [ngbNavItem]="'userData'">
                        <a ngbNavLink
                            >User data
                            <i class="far fa-exclamation-circle text-danger" aria-hidden="true" *ngIf="sessionData.invalid && settingsSubmitted"></i>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="body_content">
                                <div class="row">
                                    <div class="col-5">
                                        <label>Key</label>
                                    </div>
                                    <div class="col-5">
                                        <label>Value</label>
                                    </div>
                                </div>
                                <div formArrayName="customData">
                                    <div class="row mb-3" *ngFor="let userDataForm of sessionData.controls; let i = index" [formGroupName]="i">
                                        <ng-container *ngIf="userDataForm.get('key').value !== 'agentChannel'; else agentChannelTemplate">
                                            <div class="col-5">
                                                <input type="text" class="form-control" required placeholder="Key" formControlName="key" [ngClass]="{ 'is-invalid': userDataForm.get('key').invalid && settingsSubmitted }" />
                                                <div class="invalid-feedback" *ngIf="userDataForm.get('key').errors?.required">Key is required</div>
                                            </div>
                                            <div class="col-5">
                                                <input type="text" class="form-control" required placeholder="Value" formControlName="value" [ngClass]="{ 'is-invalid': userDataForm.get('value').invalid && settingsSubmitted }" />
                                                <div class="invalid-feedback" *ngIf="userDataForm.get('value').errors?.required">Value is required</div>
                                            </div>
                                            <div class="col-2">
                                                <button class="btn" (click)="sessionData.removeAt(i)"><i class="far fa-times-circle" aria-hidden="true"></i></button>
                                            </div>
                                        </ng-container>

                                        <ng-template #agentChannelTemplate>
                                            <div class="col-5 mt-3">
                                                <input type="text" class="form-control" required placeholder="Key" formControlName="key" readonly [ngClass]="{ 'is-invalid': userDataForm.get('key').invalid && settingsSubmitted }" />
                                            </div>
                                            <div class="col-5 mt-3">
                                                <select name="filterAgent" class="form-select" placeholder="Agent" formControlName="value">
                                                    <option [value]="'1'">App</option>
                                                    <option [value]="'2'">Web</option>
                                                    <option [value]="'3'">Facebook</option>
                                                    <option [value]="'5'">Whatsapp</option>
                                                    <option [value]="'4'">Vivocha</option>
                                                    <option [value]="'7'">TIM RCC</option>
                                                    <option [value]="'8'">Liveperson</option>
                                                    <option [value]="'9'">Google</option>
                                                    <option [value]="'10'">Voice</option>
                                                    <option [value]="'11'">Teams</option>
                                                    <option [value]="'12'">Telegram</option>
                                                    <option [value]="'6'">Test</option>
                                                </select>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                                <div class="row mb-3" *ngIf="settingsForm.get('journeyCode')">
                                    <div class="col-5">
                                        <input type="text" class="form-control" value="Journey code" readonly />
                                    </div>
                                    <div class="col-5">
                                        <input type="text" class="form-control" required placeholder="Value..." formControlName="journeyCode" [ngClass]="{ 'is-invalid': settingsForm.get('journeyCode').invalid && settingsSubmitted }" />
                                        <div class="invalid-feedback" *ngIf="settingsForm.get('journeyCode').errors?.required">Journey code is required</div>
                                    </div>
                                </div>
                                <div class="row mb-3" *ngIf="settingsForm.get('journeyCode')">
                                    <div class="col-5">
                                        <input type="text" class="form-control" value="Language" readonly />
                                    </div>
                                    <div class="col-5">
                                        <input type="text" class="form-control" required placeholder="Language..." formControlName="selectedLanguage" [ngClass]="{ 'is-invalid': settingsForm.get('selectedLanguage').invalid && settingsSubmitted }" />
                                        <div class="invalid-feedback" *ngIf="settingsForm.get('selectedLanguage').errors?.required">Language is required</div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col">
                                        <a href="javascript:void(0)" (click)="sessionData.push(getParamForm())">
                                            <small><i class="fas fa-plus" aria-hidden="true"></i> Add</small>
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-check">
                                            <input type="checkbox" class="form-check-input" id="enableTranslateResult" formControlName="translateResult" />
                                            <label class="form-check-label" for="enableTranslateResult">Use translate result</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                    <li id="responseTab" [ngbNavItem]="'parametersMap'">
                        <a ngbNavLink
                            >Parameters Map
                            <i class="far fa-exclamation-circle text-danger" aria-hidden="true" *ngIf="parametersMap.invalid && settingsSubmitted"></i>
                        </a>
                        <ng-template ngbNavContent>
                            <div class="body_content" formArrayName="parametersMap">
                                <div class="row mb-3" *ngIf="parametersMap.controls.length > 0">
                                    <div class="col-4 offset-1">
                                        <label>Parameters name</label>
                                    </div>
                                    <div class="col-4">
                                        <label>Parameters value</label>
                                    </div>
                                </div>
                                <div class="row mb-3" *ngFor="let parametersForm of parametersMap.controls; let i = index" [formGroupName]="i">
                                    <div class="col-1 d-flex justify-content-center align-items-center">
                                        <div class="form-check" ngbTooltip="Use only for next interaction">
                                            <input type="checkbox" class="form-check-input" id="parametersMap_{{ i }}" formControlName="removeOnSubmit" />
                                            <label class="form-check-label" for="parametersMap_{{ i }}"></label>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control" required placeholder="Key" formControlName="key" [ngClass]="{ 'is-invalid': parametersForm.get('key').invalid && settingsSubmitted }" />
                                        <div class="invalid-feedback" *ngIf="parametersForm.get('key').errors?.required">Key is required</div>
                                    </div>
                                    <div class="col-4">
                                        <input type="text" class="form-control" required placeholder="Value" formControlName="value" [ngClass]="{ 'is-invalid': parametersForm.get('value').invalid && settingsSubmitted }" />
                                        <div class="invalid-feedback" *ngIf="parametersForm.get('value').errors?.required">Value is required</div>
                                    </div>
                                    <div class="col-2">
                                        <button class="btn" (click)="parametersMap.removeAt(i)"><i class="far fa-times-circle" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <a href="javascript:void(0)" (click)="parametersMap.push(getParamForm())">
                                            <small><i class="fas fa-plus" aria-hidden="true"></i> Add</small>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </li>
                </ul>
                </div>
                <div class="col-2 clear_all">
                    <a href="javascript:void(0)" (click)="resetSettings()">Clear All <i class="far fa-trash-alt fa-sm" aria-hidden="true"></i></a>
                </div>
            </div>
            <div class="col-12">
                <div [ngbNavOutlet]="chatNav" class="mt-2"></div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="navbar p-0 btn-group">
            <button id="saveTestButton" type="button" class="btn brand_default" (click)="saveSettings(false)">Save</button>
            <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
                <button class="btn brand_default dropdown-toggle" ngbDropdownToggle id="dropdownTestReference"></button>
                <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu aria-labelledby="dropdownTestReference">
                    <button id="saveAndRunButton" class="dropdown-item text_brand" ngbDropdownItem (click)="saveSettings(true)">Save & Refresh</button>
                </div>
            </div>
        </div>
    </div>
</div>
