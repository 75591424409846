import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared';
import { ChatInteractionComponent } from '@standalone';
import { AnalyticsRoutingModule } from './analytics-routing.module';
import { AnalyticSessionsComponent } from './components/analytic-sessions/analytic-sessions.component';
import { AnalyticsFilterComponent } from './components/analytics-filter/analytics-filter.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { ConversationalHistoryComponent } from './components/conversational-history/conversational-history.component';
import { RatingModalComponent } from './components/rating-modal/rating-modal.component';

@NgModule({
  declarations: [
    AnalyticsComponent,
    AnalyticsFilterComponent,
    AnalyticSessionsComponent,
    ConversationalHistoryComponent,
    RatingModalComponent,
  ],
  imports: [
    CommonModule,
    AnalyticsRoutingModule,
    FormsModule,
    NgbModule,
    SharedModule,
    ReactiveFormsModule,
    NgxSliderModule,
    ChatInteractionComponent,
  ],
})
export class AnalyticsModule {}
