import { Component } from '@angular/core';
import { JourneyService } from '@core';
import { Observable, of } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { DashboardService } from '../../../services/dashboard.service';

@Component({
  selector: 'training-card',
  templateUrl: './training-card.component.html',
  styleUrls: ['./training-card.component.scss'],
})
export class TrainingCardComponent {
  subscriptions: object = {};
  statistics$: Observable<any> = this.journeyService.journey$.pipe(
    switchMap(() => {
      if (this.journeyService.isProductConfigured(['RS'])) {
        return this.dashboardService.getTrainingStatistics(new Date(2020, 0, 1, 23, 59, 59).getTime(), new Date().setHours(23, 59, 59, 999)).pipe(
          map((res: any) => ({
            assigned: res.multiObject.assigned,
            fallbacks: res.multiObject.fallbacks,
            notAnalyzed: res.multiObject.notAnalyzed,
          })),
          startWith(null)
        );
      } else {
        return of({});
      }
    }),
    shareReplay(1)
  );

  constructor(private dashboardService: DashboardService, private journeyService: JourneyService) {}
}
