import { NgModule } from '@angular/core';
import { AuthenticationModuleMocked } from '@authentication';
import { JourneyService } from '../services/journey.service';
import { UserAccountService } from '../services/user-account.service';
import { ENVIRONMENT, TEST_ENVIRONMENT } from '@environment-token';
import { TEST_JOURNEY_SERVICE, TEST_USER_ACCOUNT_SERVICE } from './services.mock';

@NgModule({
  providers: [
    { provide: JourneyService, useClass: TEST_JOURNEY_SERVICE },
    { provide: UserAccountService, useValue: TEST_USER_ACCOUNT_SERVICE },
    { provide: ENVIRONMENT, useValue: TEST_ENVIRONMENT }
  ],
  imports: [AuthenticationModuleMocked]
})
export class CoreModuleMocked {}
