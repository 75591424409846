
<section id="header_container" class="sticky-top">
    <console-header [openChat]="openChat" (switchChat)="toggleChat($event)"></console-header>
</section>

<section id="page_container">
    <div class="container-fluid">
        <div class="row">
            <div class="sidebar_nav" [ngClass]="{'stretched': stretchMenu, 'col-2': !stretchMenu}">
                <console-menu [stretchMenu]="stretchMenu"></console-menu>
            </div>

            <div class="col view_nav">
                <router-outlet></router-outlet>
            </div>

            <div class="chat_nav">
                <chat [openChat]="openChat" (switchChat)="toggleChat($event)"></chat>
            </div>
        </div>
    </div>
</section>

<toast-notification aria-live="polite" aria-atomic="true"></toast-notification>
