<nav class="console_menu">
    <ul class="list-unstyled menu_items" [ngClass]="{ streched: stretchMenu }">
        <li id="homeMenu" [routerLinkActive]="['active']">
            <a href="#" [routerLink]="['/dashboard']" ngbTooltip="Home" placement="right" [disableTooltip]="!stretchMenu" container="body">
                <i class="fas fa-home item_icon" aria-hidden="true"></i> Home
            </a>
        </li>
        <li
            #designAccordion
            [ngClass]="{ active: isChildActive(designAccordion, 'subDesign') && !subDesign }"
            *roleRestriction="['design.journey.write', 'design.journey.read', 'design.pagesettings.tags.write', 'design.pagesettings.tags.read', 'design.pagesettings.resource.write', 'design.pagesettings.resource.read', 'design.pagesettings.pagestatus.write', 'design.pagesettings.pagestatus.read', 'design.entity.write', 'design.entity.read']"
        >
            <a
                href="javascript:void(0)"
                ngbTooltip="Design"
                placement="right"
                [disableTooltip]="!stretchMenu"
                container="body"
                (click)="subDesign = !subDesign"
                [ngClass]="{ sub_open: subDesign, sub_closed: !subDesign }"
            >
                <i class="fas fa-pencil-ruler item_icon" aria-hidden="true"></i> Design
            </a>
            <ul class="list-unstyled sub_items" [ngClass]="{ open: subDesign }">
                <li id="processFlowsMenu" [routerLinkActive]="['active']" *roleRestriction="['design.journey.write', 'design.journey.read']">
                    <a href="#" [routerLink]="['/process-flow']"> <i class="fas fa-shapes item_icon" aria-hidden="true"></i> Process flows </a>
                </li>
            </ul>
        </li>
        <ng-container *journeyRestriction="[{type: 'RS'}]">
            <li id="trainingMenu" [routerLinkActive]="['active']" *roleRestriction="['training.write', 'training.read']">
                <a href="#" [routerLink]="['/training']" ngbTooltip="Training" placement="right" [disableTooltip]="!stretchMenu" container="body">
                    <i class="fas fa-chalkboard-teacher item_icon" aria-hidden="true"></i> Training
                </a>
            </li>
        </ng-container>
            <li id="analyticsMenu" [routerLinkActive]="['active']" *roleRestriction="['analytics.view']">
                <a href="#" [routerLink]="['/analytics']" ngbTooltip="Analytics" placement="right" [disableTooltip]="!stretchMenu" container="body">
                    <i class="fas fa-chart-line item_icon" aria-hidden="true"></i> Analytics
                </a>
            </li>
        <li id="monitoringMenu" [routerLinkActive]="['active']" *roleRestriction="['monitoring.status.view', 'monitoring.logs.view']">
            <a href="#" [routerLink]="['/monitoring']" ngbTooltip="monitoring" placement="right" [disableTooltip]="!stretchMenu" container="body">
                <i class="fas fa-binoculars item_icon" aria-hidden="true"></i> Monitoring
            </a>
        </li>
        <li id="adminMenu" [routerLinkActive]="['active']" *roleRestriction="['admin.user.view', 'admin.user.editor', 'admin.training.write', 'admin.training.read', 'admin.auditlog.view']">
            <a href="#" [routerLink]="['/admin']" ngbTooltip="Admin" placement="right" [disableTooltip]="!stretchMenu" container="body">
                <i class="fas fa-star item_icon" aria-hidden="true"></i> Admin
            </a>
        </li>
    </ul>

    <ul class="list-unstyled low_items">
            <li id="channelsMenu">
                <a href="#" [routerLink]="['/channels']" [routerLinkActive]="['active']" ngbTooltip="Marketplace" placement="right" [disableTooltip]="!stretchMenu" container="body">
                    <i class="fas fa-chart-network" aria-hidden="true"></i>
                </a>
            </li>
    </ul>
</nav>
