<div class="console_modal chat_debug_dialog conversation_interactions">
    <div class="modal-header">
        <section class="head_top">
            <h4 class="modal-title">Debug</h4>
            <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
                <i class="fal fa-times" aria-hidden="true"></i>
            </button>
        </section>
        <section class="head_bottom">
            <div class="row align-items-end">
                <div class="col-12 mt-2">
                    <ul ngbNav #debugNav="ngbNav" class="nav-tabs mb-0 debug_nav">
                        <li [ngbNavItem]="'response'">
                            <a href="javascript:void(0)" ngbNavLink>Response</a>

                            <ng-template ngbNavContent>
                                <div class="row" *ngIf="selectedInteraction.apiResponse | json as apiResponse">
                                    <div class="col-12 editor_wrapper">
                                        <code-editor [ngModel]="apiResponse" [readOnly]="true" language="json"></code-editor>
                                        <i class="copy_button" aria-hidden="true" class="copy_button far fa-copy" (click)="commonService.copyToClipboard(apiResponse)" ngbTooltip="Copy to Clipboard"></i>
                                    </div>
                                </div>
                            </ng-template>
                        </li>
                        <li class="ms-auto">
                            <div class="d-flex" *ngIf="botDialog.length > 1">
                                <div class="btn-group align-items-center">
                                    <button class="btn brand_light" (click)="prevInteraction()"><i class="fas fa-angle-left" aria-hidden="true"></i></button>
                                    <span class="px-3">{{ this.interactionIndex + 1 }} / {{ botDialog.length }}</span>
                                    <button class="btn brand_light" (click)="nextInteraction()"><i class="fas fa-angle-right" aria-hidden="true"></i></button>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    </div>

    <div class="modal-body">
        <div class="body_content">
            <div [ngbNavOutlet]="debugNav"></div>
        </div>
    </div>
</div>
