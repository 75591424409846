
<div class="console_modal user_settings_dialog">
    <div class="modal-header">
        <h4 class="modal-title">General settings</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.close()">
            <i class="fal fa-times" aria-hidden="true"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-3">
                <div class="btn-group settings_menu" role="group">
                    <input type="radio" name="menuFields" value="profile" class="btn-check" id="profileRadio" autocomplete="off" >
                    <label class="btn" for="profileRadio"><i class="fal fa-user-circle" aria-hidden="true"></i>Profile</label>
                </div>
            </div>
            <div class="col">
                    <setting-profile></setting-profile>
            </div>
        </div>
    </div>
</div>
