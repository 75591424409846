import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@environment-token';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {

  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private env: Environment) {}

  getAxisScale(chartData: Array<Array<any>>): any {
    const valuesFound = (item: Array<any>, index: number) => index !== 0 && item[1] > 0;
    if (!chartData.some(valuesFound)) {
      return {
        max: 100,
        min: 0,
      };
    }
    return {};
  }

  getConversationsStatistics(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate).set('fillEmptyDate', 'true'),
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/generic/conversations`, httpOptions);
  }

  getSingleInteractionSessions(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate),
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/single/interactions/total`, httpOptions);
  }

  getMultipleInteractionSessions(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate),
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/multiple/interactions/total`, httpOptions);
  }

  getInteractionsAverageSession(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate),
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/media/conversations/messages`, httpOptions);
  }

  getInteractionsAverageDuration(params: any): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', params.startDate).set('endDate', params.endDate),
    };
    if (params.tags) httpOptions.params = httpOptions.params.append('tags', params.tags);
    if (params.agentChannel) httpOptions.params = httpOptions.params.append('agentChannel', params.agentChannel);

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/graphics/media/duration/conversations`, httpOptions);
  }

  getAgentChannels(startDate: number, endDate: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', startDate.toString()).set('endDate', endDate.toString()),
    };

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/percent/agentChannel`, httpOptions);
  }

  getPercentagePopularTags(startDate: number, endDate: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', startDate.toString()).set('endDate', endDate.toString()),
    };

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/percent/tags`, httpOptions);
  }

  getPrecentageLang(startDate: number, endDate: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('startDate', startDate.toString()).set('endDate', endDate.toString())
    };

    return this.http.get(`${this.env.API.orchestrator_analysis}/analytics/percent/lang`, httpOptions);
  }
}
