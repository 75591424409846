import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AgentType, JourneyService } from '@core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { aMonthAgo, CommonApiService, Pagination, TableSortDirective, today } from '@shared';
import { Subscription } from 'rxjs';

import { RatingModalComponent } from '../rating-modal/rating-modal.component';

@Component({
  selector: 'conversational-history',
  templateUrl: './conversational-history.component.html',
  styleUrls: ['./conversational-history.component.scss'],
})
export class ConversationalHistoryComponent implements OnInit, OnDestroy {
  @ViewChildren(TableSortDirective) thead: QueryList<TableSortDirective>;

  
  interactionsSubscription: Subscription;
  journeySubscription: Subscription;
  agentLanguageSubscription: Subscription;
  exportSubscription: Subscription;
  interactionsPagination: Pagination = new Pagination();

  pageTarget = 1;
  currentPage = 1;
  pageSize = 10;
  totalPages: number;

  filterFor = 'sessionId';
  filter: any = {};
  interactions: Array<any> = [];
  subscriptions: object = {};

  constructor(
    private modalService: NgbModal,
    private commonApiService: CommonApiService,
    private journeyService: JourneyService
  ) { }

  ngOnInit() {
    this.initFilter();
    this.journeySubscription = this.journeyService.journey$.subscribe(() => {
      this.initFilter();
      this.searchInteractions(1);
    });
  }

  ngOnDestroy() {
    if (this.interactionsSubscription)
      this.interactionsSubscription.unsubscribe();
    if (this.journeySubscription)
      this.journeySubscription.unsubscribe();
    if (this.agentLanguageSubscription)
      this.agentLanguageSubscription.unsubscribe();
    if (this.exportSubscription instanceof Subscription) this.exportSubscription.unsubscribe();
  }

  viewConversation(sessionId: string, agentBotType: AgentType) {
    const modalRef = this.modalService.open(RatingModalComponent, {
      size: 'lg',
    });
    modalRef.componentInstance.sessionId = sessionId;
    modalRef.componentInstance.agentBotType = agentBotType;
  }

  searchInteractions(pageSelected: number) {
    this.interactionsPagination.onSelectPage(pageSelected);

    const params = {
      sessionId: this.filter.sessionId,
      startDate: this.filter.period.startDate,
      endDate: this.filter.period.endDate,
      intentName: this.filter.intent || null,
      userQuery: this.filter.userQuery || null,
      agentChannel: this.filter.agentChannel || null,
      sortBy: this.filter.sortBy || null,
    };

    this.interactionsSubscription = this.commonApiService
      .getInteractionHistory(params, this.interactionsPagination.getPageIndex(), this.interactionsPagination.pageSize)
      .subscribe((response: any) => {
        this.interactions = response.data;
        this.interactionsPagination.updateTotals(response.totalElements);
      });
  }

  onSort({ column, direction }: any) {
    this.thead.forEach((th: any) => {
      if (th.sortable !== column) {
        th.direction = '';
      }
    });

    this.filter.sortBy = direction ? `${column}:${direction}` : null;
    this.searchInteractions(this.interactionsPagination.currentPage);
  }


  private initFilter() {
    this.filter = {
      period: {
        startDate: aMonthAgo.setHours(0, 0, 0, 0),
        endDate: today.setHours(23, 59, 59, 0),
      },
      agentChannel: '',
    };
  }
}
