<div class="interaction_details col-12">
    <div class="row mb-3" id="userInputWrapper">
        <label for="" class="col-3 col-form-label">User says</label>
        <div class="col-9">
            <div class="input_phrase">
                    <div
                        [attr.contenteditable]="interaction.forwardNlu === null && !forbiddenTraining"
                        class="form-control input_entity"
                        [ngClass]="{ disabled: interaction.forwardNlu !== null || forbiddenTraining }"
                        [entitySelection]="interaction.editedEntityJson"
                        [userQuery]="interaction.editedUserQuery"
                        *ngIf="!uneditableView"
                        (input)="updateUserQuery($event.target)"
                    >
                        {{ interaction.editedUserQuery }}
                    </div>
                    <div class="form-control input_entity disabled" *ngIf="uneditableView" [entitySelection]="interaction.entitiesJson" [userQuery]="interaction.userQuery">{{ interaction.userQuery }}</div>
            </div>
        </div>
    </div>
    <div class="row mb-3">
        <div class="offset-3 col-9">
            <div class="table-responsive mt-3" *ngIf="showParameter">
                <table class="table parameters_table" aria-describedby="Parameters Table">
                    <thead>
                        <tr>
                            <th scope="col">Parameter Name</th>
                            <th scope="col">Resolved Value</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="!uneditableView">
                            <tr *ngFor="let parameter of interaction.editedEntityJson | keyvalue: orderBlank; let i = index">
                                <td [ngClass]="{ last_parameter: isLastParameter(interaction.editedEntityJson, i) }">
                                    <span class="matches_{{ i }}">{{ parameter.key }}</span>
                                </td>
                                <td [ngClass]="{ last_parameter: isLastParameter(interaction.editedEntityJson, i) }">{{ parameter.value | json }}</td>
                                <td class="remove_parameter" [ngClass]="{ last_parameter: isLastParameter(interaction.editedEntityJson, i) }">
                                    <button class="btn p-0" (click)="removeParameter(interaction, parameter.key)" *ngIf="interaction.forwardNlu == null && !forbiddenTraining"><i class="fas fa-times" aria-hidden="true"></i></button>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container *ngIf="uneditableView">
                            <tr *ngFor="let parameter of interaction.entitiesJson | keyvalue: orderBlank; let i = index">
                                <td [ngClass]="{ last_parameter: isLastParameter(interaction.entitiesJson, i) }">
                                    <span class="matches_{{ i }}">{{ parameter.key }}</span>
                                </td>
                                <td colspan="2" [ngClass]="{ last_parameter: isLastParameter(interaction.entitiesJson, i) }">{{ parameter.value | json }}</td>
                            </tr>
                        </ng-container>
                        <tr class="context_parameters" *ngFor="let parameter of getContextParameters(interaction.outputContextJson) | keyvalue">
                            <td>{{ parameter.key }}</td>
                            <td colspan="2">{{ parameter.value | json }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row mb-3" id="intentWrapper">
        <label for="" class="col-3 col-form-label">Intent</label>
        <div class="col-9 d-flex align-items-center">
            <a
                id="trainingIntent"
                href="javascript:void(0)"
                class="edit_intent"
                [ngClass]="{ disable_intent_changing: !trainableIntent(interaction) }"
                [ngbPopover]="popIntentContent"
                triggers="manual"
                #popIntent="ngbPopover"
                [popoverClass]="'popover_intent'"
                [placement]="'right-top'"
                [autoClose]="'outside'"
                [disablePopover]="!trainableIntent(interaction) || forbiddenTraining"
                (click)="togglePopIntent()"
            >
                <span *ngIf="!uneditableView">{{ interaction.editedIntentId || 'Select correct intent' }}</span>
                <span *ngIf="uneditableView">{{ interaction.intentId || 'No matched intent' }}</span>
            </a>
        </div>
    </div>

    <div class="row mb-3">
        <label class="col-3 col-form-label">Confidence</label>
        <div class="col-9 d-flex align-items-center">
            <p class="mb-0">{{ interaction.confidence | number: '1.0-2' }}</p>
        </div>
    </div>

    <div class="row">
        <label class="col-3 col-form-label">Response</label>
        <div class="col-9">
            <p class="mb-1" [ngClass]="{ 'text-muted': !interaction.isShownMessage }" *ngFor="let message of getSplittedMessages(interaction.outputMessage)">{{ message }}</p>
        </div>
    </div>
</div>

<ng-template #popIntentContent>
    <div id="intentPopover">
        <form #searchIntentForm="ngForm" novalidate>
            <input type="text" name="intentFilter" class="form-control" [(ngModel)]="intentFilter" #ctrl="ngModel" />
        </form>

        <ul class="list-unstyled popover_list">
            <li *ngFor="let intent of intents | fullTextSearch: intentFilter:'name'">
                <a href="javascript:void(0)" (click)="setIntent(intent)">{{ intent.name }}</a>
            </li>
        </ul>
    </div>
</ng-template>
