import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@environment-token';
import { Observable, of, zip } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  
  constructor(private http: HttpClient, @Inject(ENVIRONMENT) private env: Environment) {}

  getChatStatus() {
    return this.http.get<any>(`${this.env.API.orchestrator_chat}/actuator/health`).pipe(
      map((res) => {
        if (res.components && res.components.redis) {
          res.components.cache = { ...res.components.redis };
          delete res.components.redis;
        }
        return res;
      }),
      catchError((res) => {
        const body = res.error;
        if (body.components && body.components.redis) {
          body.components.cache = { ...body.components.redis };
          delete body.components.redis;
        }
        return of(body);
      })
    );
  }
  getAnalysisStatus() {
    return this.http.get(`${this.env.API.orchestrator_analysis_v2}/actuator/health`).pipe(catchError((res) => of(res.error)));
  }
  getConfiguratorStatus() {
    return this.http.get(`${this.env.API.orchestrator_configurator}/actuator/health`).pipe(catchError((res) => of(res.error)));
  }
  getRasaModelServerStatus(){
    return this.http.get(`${this.env.API.orchestrator_rasa_model}/actuator/health`).pipe(catchError((res) => of(res.error)));
  }

  restartLivepersonAgent(agentNames: string[]) {
    let httpOptions;
    if (agentNames && agentNames.length > 0) {
      httpOptions = {
        params: {
          agent: agentNames,
        },
      };
    }
    return this.http.post(`${this.env.API.orchestrator_configurator}/liveperson/restartAgents`, null, httpOptions);
  }

  getAgentHealth(agentNames?: string[]): Observable<any> {
    let httpOptions;
    if (agentNames && agentNames.length > 0) {
      httpOptions = {
        params: {
          agent: agentNames,
        },
      };
    }
    return this.http.get(`${this.env.API.orchestrator_configurator}/liveperson/checkAgentsStatus`, httpOptions).pipe(
      map((res: any) => {
        const retVal = { status: res.status, agents: {} };
        res.agents.forEach((agent) => {
          retVal.agents[agent.agent] = { status: agent.status };
        });
        return retVal;
      }),
      catchError(() => {
        return of({ status: 'UP', agents: {} });
      })
    );
  }


  getConsoleStatus() {
    const httpOptions = {
      responseType: 'text' as any,
    };

    return this.http.get(`/`, httpOptions).pipe(map(() => ({ status: 'UP' })));
  }

  getLogsConnectors() {
    return this.http.get<Array<string>>(`${this.env.API.orchestrator_analysis}/gcplogging/getConnectors`);
  }
  getLogs(params: any, pageNumber: number, pageSize: number): Observable<any> {
    const httpOptions = {
      params: new HttpParams().set('pageSize', pageSize.toString()).set('pageIndex', pageNumber.toString()),
    };

    const allowedParams = ['connectors', 'startDate', 'endDate', 'severity', 'textLog'];
    for (const key of allowedParams) {
      if (params[key] !== null && params[key] !== undefined) {
        if (key === 'connectors' || key === 'severity') {
          params[key].forEach((c) => {
            httpOptions.params = httpOptions.params.append(key, `${c.toString()}`);
          });
        } else {
          httpOptions.params = httpOptions.params.append(key, params[key].toString());
        }
      }
    }
    return this.http.get(`${this.env.API.orchestrator_analysis}/gcplogging`, httpOptions);
  }
}
