
<div class="row section_wrapper">
    <div class="col-12">
        <ul ngbNav #analyticsNav="ngbNav" class="nav-tabs analytic_tabs" [activeId]="activeTab">
            <li id="sessionsTab" [ngbNavItem]="'sessions'">
                <a ngbNavLink [routerLinkActive]="['active']" [routerLink]="['/analytics/sessions']">Sessions</a>
            </li>
            <li id="historyTab" [ngbNavItem]="'conversational-history'">
                <a ngbNavLink [routerLinkActive]="['active']" [routerLink]="['/analytics/conversational-history']">History</a>
            </li>
        </ul>
    </div>

    <div class="col-12">
        <router-outlet></router-outlet>
    </div>
</div>
