import { asyncScheduler, of, scheduled } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { RxStompService } from '../services/rx-stomp.service';

// export const TEST_OAUTH_SERVICE = {
//   initCodeFlow: () => true,
//   hasValidAccessToken: () => true,
//   configure: () => {},
//   loadDiscoveryDocumentAndLogin: () => Promise.resolve(),
//   tryLogin: () => {},
//   setupAutomaticSilentRefresh: () => {},
//   getAccessToken: () => '',
//   initImplicitFlow: () => {},
// };

export const TEST_RXSTOMP_SERVICE: Partial<RxStompService> = {
  configure: () => null,
  activate: () => null,
  publish: () => null,
  watch: () => of({ body: 'test' } as any, asyncScheduler),
  importMultiIntents: () => scheduled(of({ body: 'test' }) as any, asyncScheduler),
  importTemplateOverride: () => scheduled(of({ body: 'test' }) as any, asyncScheduler),
  importTemplate: () => scheduled(of({ body: 'test' }) as any, asyncScheduler),
  esportAgentHistory: () => scheduled(of({ body: 'test' }) as any, asyncScheduler),
  exportLog: () => scheduled(of({ body: 'test' }) as any, asyncScheduler),
};

export const TEST_AUTHENTICATION_SERVICE: Partial<AuthenticationService> = {
  initAuth: () => new Promise((resolve) => resolve(true)),
  logout: () => null,
  hasValidToken: () => true,
  refreshToken: () => new Promise((resolve) => resolve(true as any)),
  changePassword: () => null,
};
