import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerLoaderDirective } from './directives/spinner-loader.directive';
import { NotificationService } from './services/notification.service';
import { SpinnerLoaderComponent } from './components/spinner-loader/spinner-loader.component';
import { NotificationModalComponent } from './components/notification-modal/notification-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [NotificationModalComponent, SpinnerLoaderComponent, SpinnerLoaderDirective],
  imports: [CommonModule, NgbModule, FormsModule],
  providers:[NotificationService],
  exports:[
    NotificationModalComponent,
    SpinnerLoaderDirective,
    SpinnerLoaderComponent
  ]

})
export class CommonUiModule {}
