import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MonitoringService } from '../../services/monitoring.service';
import { HealthStatusModalComponent } from '../health-status-modal/health-status-modal.component';



@Component({
  selector: 'health-status',
  templateUrl: './health-status.component.html',
  styleUrls: ['./health-status.component.scss'],
})
export class HealthStatusComponent implements OnInit, OnDestroy {
  availablePods = ['Chat', 'Analysis', 'Configurator', 'Console', 'Rasa Model Server'];

  pods = [];

  componentLoaded = false;

  subscriptions = {};
  constructor(private monitoringService: MonitoringService, private modalService: NgbModal) { }

  ngOnInit() {
    this.loadPodStatuses();
  }

  ngOnDestroy(): void {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  async loadPodStatuses() {
    this.pods = [];
    for (const name of this.availablePods) {
      this.pods.push(await this.getComponentStatus(name, name));
    }
    this.componentLoaded = true;
  }
  private async getComponentStatus(code, name) {
    try {
      let status;
      switch (code) {
        case 'Chat':
          status = await this.monitoringService.getChatStatus().toPromise();
          break;
        case 'Analysis':
          status = await this.monitoringService.getAnalysisStatus().toPromise();
          break;
        case 'Configurator':
          status = await this.monitoringService.getConfiguratorStatus().toPromise();
          break;
        case 'Console':
          status = await this.monitoringService.getConsoleStatus().toPromise();
          break;
        case 'Rasa Model Server':
          status = await this.monitoringService.getRasaModelServerStatus().toPromise();
          break;
      }
      return { ...status, name };
    } catch (error) {
      return {
        name,
        status: 'DOWN',
      };
    }
  }

  statusModal(statuses) {
    if (statuses.components) {
      const modalRef = this.modalService.open(HealthStatusModalComponent);
      modalRef.componentInstance.statuses = statuses;
    }
  }
}
