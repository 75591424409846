import { asyncScheduler, of, scheduled } from 'rxjs';
import { TEST_COMPONENT_STATUS, TEST_CONSUMPTIONS_DF, TEST_GET_LOGS, TEST_LOGS_CONNECTORS } from './calls.mock';

export const TEST_MONITORING_SERVICE = {
  getRequestStats: () => scheduled(of(TEST_CONSUMPTIONS_DF), asyncScheduler),
  getChatStatus: () => scheduled(of(TEST_COMPONENT_STATUS), asyncScheduler),
  getAnalysisStatus: () => scheduled(of(TEST_COMPONENT_STATUS), asyncScheduler),
  getConfiguratorStatus: () => scheduled(of(TEST_COMPONENT_STATUS), asyncScheduler),
  getRasaModelServerStatus: () => scheduled(of(TEST_COMPONENT_STATUS), asyncScheduler),
  getConsoleStatus: () => scheduled(of(TEST_COMPONENT_STATUS), asyncScheduler),
  getLogsConnectors: () => scheduled(of(TEST_LOGS_CONNECTORS), asyncScheduler),
  getLogs: () => scheduled(of(TEST_GET_LOGS), asyncScheduler),
};
