import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { CommonApiService, SharedModule } from '@shared';
import { AgentType, Languages, UserAccountService } from '@core';
import { EntitySelectionDirective } from '../../directives/entity-selection.directive';

@Component({
  selector: 'chat-interaction',
  templateUrl: './chat-interaction.component.html',
  styleUrls: ['./chat-interaction.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [EntitySelectionDirective, SharedModule],
})
export class ChatInteractionComponent implements OnInit, OnDestroy {
  forbiddenTraining = false;
  @Input() uneditableView? = false;

  private _interaction;
  get interaction() {
    return this._interaction;
  }
  @Input() set interaction(interaction) {
    this._interaction = interaction;
  }
  @Input() agentBotType: AgentType;
  @Input() intents;

  @ViewChild('popIntent') popIntent: NgbPopover;
  @ViewChild('popEntity') popEntity: NgbPopover;
  entityPopOffset: string;

  Languages = Languages;
  subscriptions: object = {};
  entities: Array<any> = [];
  intentFilter;
  entityFilter;

  get showParameter() {
    return (
      Object.keys(this.interaction.editedEntityJson || {}).length > 0 ||
      Object.keys(this.getContextParameters(this.interaction.outputContextJson) || {}).length > 0
    );
  }

  trainableIntent(interaction) {
    return (
      (this.agentBotType === AgentType.RS) && !this.uneditableView && interaction.forwardNlu == null
    );
  }

  constructor(private commonApiService: CommonApiService, private userAccountService: UserAccountService) {}

  ngOnInit() {
    if (!this.userAccountService.isAuthorized('training.write')) {
      this.forbiddenTraining = true;
    }
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  getEntities(intentName: string, lang: string) {
    this.subscriptions['Entities'] = this.commonApiService.getEntities({ intentName: intentName, agentLang: lang }).subscribe((response: any) => {
      this.entities = response.singleResult.parameters;
    });
  }

  getSplittedMessages(outputMessage: string): Array<string> {
    return outputMessage.split('##');
  }

  getContextParameters(outputContextJson: Array<any>) {
    const contextParameters = {};

    outputContextJson?.forEach((context: any) => {
      for (const key in context.parameters) {
        if (
          Object.prototype.hasOwnProperty.call(context.parameters, key) &&
          !this.interaction.editedEntityJson[key] &&
          !key.includes('.original') &&
          !contextParameters[key]
        ) {
          contextParameters[key] = context.parameters[key];
        }
      }
    });

    return contextParameters;
  }

  orderBlank() {
    return 0;
  }

  isLastParameter(parametersObject: any, index: number): boolean {
    return Object.keys(parametersObject).length === index + 1;
  }

  togglePopIntent() {
    if (this.popIntent.isOpen()) {
      this.popIntent.close();
    } else {
      this.popIntent.open();
    }
  }

  setIntent(intent: any) {
    this.interaction.editedIntentId = intent.name;
    this.interaction.nluPayloadIntent = intent;
    this.popIntent.close();
    this.interaction.editedEntityJson = {};
  }

  removeParameter(interaction: any, parameterKey: any) {
    delete interaction.editedEntityJson[parameterKey];
  }

  updateUserQuery(target: EventTarget) {
    const textContent = (target as HTMLInputElement).textContent;
    this.interaction.editedUserQuery = textContent;
  }

}
