<div class="row mb-3" [spinnerLoader]="subscriptions['Connectors']?.closed">
    <div class="col-12">
        <form #intentsFilter="ngForm" class="logs_filter" (ngSubmit)="searchLogs(1)" novalidate>
            <div class="row">
                <div class="col-sm-12 col-md-4">
                    <div class="input-group">
                        <input name="filterTextLog" type="text" id="filterTextLog" class="form-control" placeholder="Search logs..." [(ngModel)]="filter.textLog" #filterTextLog="ngModel" />
                        <button type="submit" class="btn brand_default"><i class="fas fa-search" aria-hidden="true"></i></button>
                    </div>
                </div>
                <div class="col-sm-12 col-md-2">
                    <div ngbDropdown (openChange)="$event === false ? searchLogs(1) : null">
                        <button type="button" class="form-select custom_select_dropdown" id="dropdownSeverities" ngbDropdownToggle>Severity</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownSeverities">
                            <div class="form-check mx-2">
                                <input type="checkbox" class="form-check-input" id="all_severities" (change)="selectAllItem('severity')" [checked]="filter.severity.length === severities.length" [indeterminate]="filter.severity.length > 0 && filter.severity.length < severities.length" />
                                <label class="form-check-label" for="all_severities">All Logs</label>
                            </div>
                            <div *ngFor="let severity of severities; let index = index" class="form-check mx-2">
                                <input name="{{severity}}" type="checkbox" class="form-check-input" id="{{severity}}" [checked]="isSelected(severity, 'severity')" (change)="selectItem(severity, 'severity')" />
                                <label class="form-check-label" for="{{ severity }}">
                                    <i
                                        aria-hidden="true"
                                        class="fas fa-xs fa-circle me-1"
                                        [ngClass]="{
                                            'text-danger': severity === 'ERROR',
                                            'text-warning': severity === 'WARNING',
                                            'text-primary': severity === 'INFO',
                                            'text-secondary': severity === 'DEBUG'
                                        }"
                                    ></i>
                                    {{ severity }}</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-2">
                    <div ngbDropdown (openChange)="$event === false ? searchLogs(1) : null">
                        <button type="button" class="form-select custom_select_dropdown" id="dropdownConnectors" ngbDropdownToggle>Connectors</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownConnectors">
                            <div class="form-check mx-2">
                                <input type="checkbox" class="form-check-input" id="all_connector" (change)="selectAllItem('connectors')" [checked]="filter.connectors.length === connectors.length" [indeterminate]="filter.connectors.length > 0 && filter.connectors.length < connectors.length" />
                                <label class="form-check-label" for="all_connector">All Connectors</label>
                            </div>
                            <div *ngFor="let connector of connectors; let index = index" class="form-check mx-2">
                                <input name="{{connector}}" type="checkbox" id="{{connector}}" class="form-check-input" [checked]="isSelected(connector, 'connectors')" (change)="selectItem(connector, 'connectors')" />
                                <label class="form-check-label" for="{{ connector }}">{{ connector }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-3 ms-auto">
                    <datepicker-range name="datepicker" [(ngModel)]="filter.period" (ngModelChange)="filter.period.startDate && filter.period.endDate && searchLogs(1)"></datepicker-range>
                </div>
                <div class="col-auto">
                    <div ngbDropdown class="kebab_menu">
                        <button type="button" class="btn" id="optionsLogs" ngbDropdownToggle><i class="fas fa-ellipsis-v" aria-hidden="true"></i></button>
                        <div ngbDropdownMenu aria-labelledby="optionsLogs">
                            <button type="button" ngbDropdownItem (click)="exportLogs()">Export Local</button>
                            <button type="button" ngbDropdownItem (click)="exportModal()" disabled>Export Storage</button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 text-end mt-1">
                    <a href="javascript:void(0)" (click)="resetFilters()">
                        <small>Clear Filter <i class="fas fa-filter" aria-hidden="true"></i></small>
                    </a>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="row">
    <div class="col-12">
        <div class="table-responsive logs_table">
            <table class="table table-hover" aria-describedby="Logs Table" [spinnerLoader]="subscriptions['LogsSubscription']?.closed">
                <thead>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col">Log Report</th>
                        <th scope="col">Log Type</th>
                        <th scope="col">Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let log of logs" (click)="log.expanded = !log.expanded">
                        <td>
                            <i
                                aria-hidden="true"
                                class="fas fa-xs fa-circle"
                                [ngClass]="{
                                    'text-danger': log.severity === 'ERROR',
                                    'text-warning': log.severity === 'WARNING',
                                    'text-primary': log.severity === 'INFO',
                                    'text-secondary': log.severity === 'DEBUG'
                                }"
                            ></i>
                        </td>
                        <td class="name_col">
                            <span [ngClass]="log.expanded ? 'expand' : 'collapse'">{{ log.logName }}</span>
                        </td>
                        <td>{{ log.severity }}</td>
                        <td>{{ log.timestamp | date: 'dd/MM/yyyy - H:mm:ss' }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="col-12 d-flex justify-content-center" *ngIf="logs.length >= pagination.currentPage * pagination.pageSize">
        <button class="btn brand_default" (click)="nextPage()">View more results</button>
    </div>
</div>
