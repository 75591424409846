import { Component, OnInit } from '@angular/core';
import { AgentType, JourneyService } from '@core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '@shared';

@Component({
  selector: 'chat-debug-modal',
  templateUrl: './chat-debug-modal.component.html',
  styleUrls: ['./chat-debug-modal.component.scss'],
})
export class ChatDebugModalComponent implements OnInit {
  public dialog: any[];
  public selectedInteraction: any;
  public botDialog: any[];
  public interactionIndex: number;
  public agentBotType;
  //public elemResized = false;
  constructor(public activeModal: NgbActiveModal, private journeyService: JourneyService, public commonService: CommonService) {}

  ngOnInit(): void {
    this.botDialog = this.dialog.filter((d) => d.type === 'bot');
    this.agentBotType = AgentType[this.journeyService.journey.type];
    this.interactionIndex = this.botDialog.findIndex((d) => d === this.selectedInteraction);
  }

  prevInteraction() {
    if (this.interactionIndex === 0) return;
    this.selectedInteraction = this.botDialog[--this.interactionIndex];
  }

  nextInteraction() {
    if (this.interactionIndex === this.botDialog.length - 1) return;
    this.selectedInteraction = this.botDialog[++this.interactionIndex];
  }
}
