import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Environment, ENVIRONMENT } from '@environment-token';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { JourneyService } from './journey.service';
import { Account } from '../models/account.model';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserAccountService {
  public user: User;
  public userAccounts: Account[];
  public sessionLoaded = false;

  private onChangeAccount: ReplaySubject<Account> = new ReplaySubject<Account>(1);
  get sessionAccount$(): Observable<Account> {
    return this.onChangeAccount.asObservable();
  }
  constructor(
    private http: HttpClient,
    private journeyService: JourneyService,
    private injector: Injector,
    @Inject(ENVIRONMENT) private env: Environment
  ) {}

  initSession(): Observable<boolean> {
    return this.getAccounts().pipe(
      switchMap(() => this.getSessionAccountData()),
      map(() => true),
      tap(() => (this.sessionLoaded = true))
    );
  }

  private getSessionAccountData() {
    return combineLatest([this.getUser(), this.journeyService.getJourneysList()]);
  }

  private getAccounts(): Observable<Account[]> {
    return this.http
      .get<Account[]>(`${this.env.API.orchestrator_configurator}/accounts`)
      .pipe(tap((accounts: Account[]) => (this.userAccounts = accounts)));
  }

  private getUser(): Observable<User> {
    return this.http.get(`${this.env.API.orchestrator_configurator}/auth`).pipe(
      tap((user: User) => {
        this.user = user;
        const account = this.userAccounts.find((a) => a.id === this.user.accountId);
        this.onChangeAccount.next(account);
      })
    );
  }

  changeAccountUser(data: any): Observable<any> {
    return this.http.put(`${this.env.API.orchestrator_configurator}/auth/changeAccount`, data).pipe(switchMap(() => this.getSessionAccountData()));
  }

  isAuthorized(restrictedPermission: Array<string> | string): boolean {
    if (!Array.isArray(restrictedPermission)) restrictedPermission = [restrictedPermission];

    return restrictedPermission.some((permission) => this.user.permissions.includes(permission));
  }
}
