import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss'],
})
export class AnalyticsComponent implements OnInit {
  @ViewChild('analyticsNav', { static: true }) analyticsNav: NgbNav;

  get activeTab() { return this.route.snapshot.firstChild.routeConfig.path }

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    if (['sessions', 'intents', 'proficiency', 'conversational_history'].indexOf(this.analyticsNav.activeId) < 0) {
      this.router.navigate(['/analytics/sessions']);
    }
  }
}
