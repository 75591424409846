import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, ENVIRONMENT } from '@environment-token';
import { Observable } from 'rxjs';
import { JourneyService } from '../services/journey.service';

@Injectable()
export class JourneyInterceptor implements HttpInterceptor {
  interceptConfigurator: Array<string> = [
    `${this.env.API.orchestrator_configurator}/analytics/trustedIntents`,
    `${this.env.API.orchestrator_configurator}/intents`,
    `${this.env.API.orchestrator_configurator}/intents/training`,
    `${this.env.API.orchestrator_configurator}/intents/synchronize`,
    `${this.env.API.orchestrator_configurator}/intents/parameters`,
    `${this.env.API.orchestrator_configurator}/intents/flow`,
    `${this.env.API.orchestrator_configurator}/journey/embeddedConfiguration`,
    `${this.env.API.orchestrator_configurator}/journey/list/configuration`,
    `${this.env.API.orchestrator_configurator}/entities`,
    `${this.env.API.orchestrator_configurator}/regression`,
    `${this.env.API.orchestrator_configurator}/faq/extractWithFile`,
    `${this.env.API.orchestrator_configurator}/liveperson/checkAgentsStatus`,
    `${this.env.API.orchestrator_configurator}/liveperson/restartAgents`,
    `${this.env.API.orchestrator_configurator}/intents/exportConfiguration`,
    `${this.env.API.orchestrator_configurator}/flow/exportConfiguration`,
    `${this.env.API.orchestrator_configurator}/flow/page/tags`,
    `${this.env.API.orchestrator_configurator}/tags/changeStatus`,
    `${this.env.API.orchestrator_configurator}/tags`,
    `${this.env.API.orchestrator_configurator}/tags/getByApikey`,
    `${this.env.API.orchestrator_configurator}/sync/synchronize`,
    `${this.env.API.orchestrator_configurator}/placeholder`,
    `${this.env.API.orchestrator_configurator}/flow/list`,
    `${this.env.API.orchestrator_configurator}/pageElementResponse/flowList`,
    `${this.env.API.orchestrator_configurator}/pageElementResponse/elementResponse`,
    `${this.env.API.orchestrator_configurator}/pageElementResponse/updateResponse`,
    `${this.env.API.orchestrator_configurator}/pageElementResponse/groupResponse`,
    `${this.env.API.orchestrator_configurator}/regression/import`,
    `${this.env.API.orchestrator_configurator}/tagUser/getTagTree`,
    `${this.env.API.orchestrator_configurator}/untrustedConfiguration`,
    `${this.env.API.orchestrator_configurator}/flow/createRouteGroup`,
    `${this.env.API.orchestrator_configurator}/product/getFilteredConnectors`,
    `${this.env.API.orchestrator_analysis}/nlu/integrations/intents/search`,
    `${this.env.API.orchestrator_configurator}/flow/getFullHierarchy`,
  ];

  urlMatcher = new RegExp(`${this.interceptConfigurator.join('|')}`);

  constructor(private journeyService: JourneyService, @Inject(ENVIRONMENT) private env: Environment) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if ((req.url.indexOf(this.env.API.orchestrator_analysis) !== -1 || this.urlMatcher.test(req.url)) && !req.params.has('journeyApiKey')) {
      return next.handle(
        req.clone({
          params: req.params.set('journeyApiKey', this.journeyService.journey.apiKey),
        })
      );
    }

    return next.handle(req);
  }
}
