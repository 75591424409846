import { Subject } from 'rxjs';
import { OrchestrateDialog } from '../models/orchestrate-dialog.model';
import { OrchestrateInteraction } from '../models/orchestrate-interaction.model';
import { OrchestrateMessage } from '../models/orchestrate-message.model';
import { OrchestrateResponse } from '../models/orchestrate-response.model';

export const DEFAULT_SESSION_CHAT_SETTINGS: any = {
  sessionUserData: {
    agentChannel: 6,
  },
  parametersMap: [],
  contexts: [],
  translateResult: false,
};
export class Chatbot {
  sessionId: string;
  sessionKey: string;
  sessionLang: string;
  journeyCode: string;
  sessionUserData: any;
  parametersMap = [];

  get formattedParametersMap() {
    if (this.parametersMap.length === 0) return null;
    return this.parametersMap.reduce((acc, p) => ({ ...acc, [p.key]: p.value }), {});
  }
  contexts = [];
  translateResult = false;

  inputMessage = '';
  inputEvent = '';
  get formattedEvent() {
    if (!this.inputEvent) return null;
    return {
      name: this.inputEvent || null,
      data: this.inputEvent ? this.formattedParametersMap : null,
    };
  }

  get formattedContexts() {
    if (this.contexts.length === 0) return null;
    return this.contexts.map(({ name, lifespan, paramKey, paramValue }) => ({
      name,
      lifespan,
      parameters: paramKey ? { [paramKey]: paramValue } : null,
    }));
  }
  files = {};
  dialog: OrchestrateDialog[] = [];

  onRestoreSession: Subject<any> = new Subject<any>();
  currentSettings: any;
  constructor(chatbotKey: string, sessionLang, sessionSettings = DEFAULT_SESSION_CHAT_SETTINGS) {
    this.sessionId = this.generateSessionId();
    this.sessionKey = chatbotKey;
    this.setSettings(sessionSettings, sessionLang);
  }

  setSettings(sessionSettings, sessionLang?) {
    this.currentSettings = sessionSettings;

    this.sessionLang = sessionSettings.sessionLang || sessionLang;
    this.sessionUserData = sessionSettings.sessionUserData;
    this.translateResult = sessionSettings.translateResult;
    this.journeyCode = sessionSettings.journeyCode;
    this.contexts = sessionSettings.contexts;
    this.parametersMap = sessionSettings.parametersMap;
  }
  generateSessionId(): string {
    const allowChars = 'ABCDEF0123456789';
    let sessionId = new Date().getTime().toString();

    for (let i = 0; i < 24 && sessionId.length < 24; i++) {
      sessionId += allowChars.charAt(Math.floor(Math.random() * allowChars.length));
    }

    return sessionId;
  }

  restoreChatbot(sessionSettings: any) {
    this.onRestoreSession.next(sessionSettings);
  }

  processOrchestrateResponse(userRequest, orchestrateResponse: OrchestrateResponse) {

    this.clearUserInput();

    const interaction: OrchestrateInteraction = {
      lang: userRequest.lang,
      eventName: userRequest.event ? userRequest.event.name : null,
      userQuery: orchestrateResponse.results.aiResponse.result.resolvedQuery || null,
      entitiesJson: orchestrateResponse.results.aiResponse.result.parameters,
      editedEntityJson: orchestrateResponse.results.aiResponse.result.parameters,
      intentName: orchestrateResponse.results.aiResponse.result.metadata.intentName,
      outputContextJson: orchestrateResponse.results.aiResponse.result.contexts,
      pageName: orchestrateResponse.results.aiResponse.result.metadata.landedPage?.name,
      flowName: orchestrateResponse.results.aiResponse.result.metadata.flow?.name,
      outputMessage: this.processDebugMessages(orchestrateResponse.results.aiResponse.result.fulfillment.messages),
      confidence: orchestrateResponse.results.aiResponse.result.score,
      sentiment: orchestrateResponse.results.naturalLanguageResponse?.sentiment || null,
      magnitude: orchestrateResponse.results.naturalLanguageResponse?.magnitude || null,
      trusted: orchestrateResponse.extraResult.details.trusted,
    };

    const data = {
      interaction,
      apiResponse: orchestrateResponse,
      detectedInteraction: this.processInteraction(interaction),
    };
    if (this.translateResult && orchestrateResponse.results.translateResponse_answer) {
      this.updateDialog('bot', [{ type: 'text', text: orchestrateResponse.results.translateResponse_answer.textResult }], data);
    } else {
      this.updateDialog(
        'bot',
        [
          ...orchestrateResponse.results.aiResponse.result.fulfillment.messages
        ],
        data
      );
    }
  }

  updateDialog(source: 'bot' | 'user', messages: Array<OrchestrateMessage>, info?: any) {
    this.dialog.push({ type: source, messages, ...info });
  }

  addInteractionMessage(index, message) {
    this.dialog[index].messages.push(message);
  }

  private processDebugMessages(messages: Array<any>): string {
    return messages
      .filter((m) => m.text)
      .map((m) => m.text)
      .join('##');
  }

  clearUserInput() {
    this.inputMessage = '';
    this.inputEvent = '';
    this.files = {};
    this.contexts = this.contexts.filter((c) => !c.removeOnSubmit);
    this.parametersMap = this.parametersMap.filter((p) => !p.removeOnSubmit);
  }

  private processInteraction({ intentName, flowName, pageName }) {
    return flowName ? `${flowName} - ${pageName}` : intentName;
  }

  cleanGraphicMessages() {
    if (!this.dialog.length) return;
    
    this.dialog[this.dialog.length - 1].messages = this.dialog[this.dialog.length - 1].messages.filter(
      (m) => !(m.type === 'suggestions' || m.type === 'uploadFiles' || m.items?.some((i) => i.button.type === 'value'))
    );
  }
}
