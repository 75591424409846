import { Inject, Injectable, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Environment, ENVIRONMENT } from '@environment-token';
import { NotificationModalComponent } from '../components/notification-modal/notification-modal.component';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NotificationModalOptions } from '../models/notification-modal-options';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private modalSpinner: any;

  toasts: any[] = [];

  constructor(private modalService: NgbModal, private http: HttpClient, @Inject(ENVIRONMENT) private env: Environment) {}

  openModal(options: NotificationModalOptions): Observable<any> {
    const modalRef = this.modalService.open(NotificationModalComponent, {
      size: options.size || null,
    });
    modalRef.componentInstance.options = options;

    return new Observable<any>((observer) => {
      modalRef.result.then(
        (result: boolean) => {
          observer.next(result);
        },
        (reason: any) => {
          observer.next(reason);
        }
      );
    });
  }

  showToast(textOrTpl: string | TemplateRef<any>, options: { type?: 'success' | 'danger' | 'warning'; delay?: number; autohide?: boolean } = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  removeToast(toast: any) {
    this.toasts = this.toasts.filter((t) => t != toast);
  }

  showModalSpinner() {
    this.modalSpinner = this.modalService.open(NotificationModalComponent, {
      size: 'sm',
      centered: true,
      keyboard: false,
    });
    this.modalSpinner.componentInstance.options = {
      type: 'spinner',
    };
  }

  hideModalSpinner() {
    this.modalSpinner?.close();
  }
}
