<div class="row mb-5">
    <div class="col-auto ms-auto"><i aria-hidden="true" class="fas fa-xs fa-circle text-danger pe-2"></i>Unreachable</div>
    <div class="col-auto me-3"><i aria-hidden="true" class="fas fa-xs fa-circle text-success pe-2"></i>Available</div>
</div>
<div class="row position-relative">
    <div class="col-12" [spinnerLoader]="componentLoaded">
        <div class="row mb-5">
            <div class="col-12 mb-3">Microservices</div>
            <div class="col-12">
                <div class="row">
                    <div class="col-lg-3 col-md-4 mb-3" *ngFor="let pod of pods">
                        <component-status-card [name]="pod.name" [status]="pod.status" (click)="statusModal(pod)"></component-status-card>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
