import { AgentType } from "../enums/agents.enum";
import { Agent } from "./agent.model";


export interface Journey {
  apiKey: string;
  createdBy: string | null;
  createdDate: number | null;
  description: string | null;
  id: number;
  lastModifiedDate: number | null;
  name: string;
  type: keyof typeof AgentType | null;
  agent?: Agent;
  status?: string;
}
