<div class="chat_sidebar" [ngClass]="{ active: openChat }">
    <div class="row g-0 sidebar_body">
        <div class="col-12 chat_head">
            <button type="button" class="btn close_chat" (click)="toggleChat(openChat)"><i class="material-icons" aria-hidden="true">close</i></button>
            <button type="button" class="btn reset_chat" (click)="initChatSession(botSession.currentSettings)"><i class="fal fa-redo" aria-hidden="true"></i></button>
            <button type="button" class="btn settings_chat" (click)="editCustomData()"><i class="fas fa-cog" aria-hidden="true"></i></button>
        </div>

        <div class="col-12 chat_modules" *ngIf="botSession">
            <ng-template [spinnerLoader]="subscriptions['ChatSubscription']?.closed"></ng-template>

            <section class="dialog_module" id="chatWrapper">
                <div #chatDialog class="module_body" [scrollTop]="chatDialog.scrollHeight">
                    <section class="speech_row" *ngFor="let interaction of botSession.dialog; let interactionIndex = index; let lastInteraction = last">
                        <article class="speech_message" *ngFor="let message of interaction.messages; let messageIndex = index; let lastMessage = last" [ngClass]="{ bot_message: interaction.type === 'bot', user_message: interaction.type === 'user', graphics_message: message.payload }">
                            <span class="speech_text" *ngIf="message.text" [innerHTML]="message.text"></span>
                            <span class="speech_event" *ngIf="message.event"> {{ message.event }}</span>

                            <section class="speech_graphics type_{{ message.type }}" [ngSwitch]="message.type">
                                <ng-container *ngSwitchCase="'suggestions'">
                                    <owl-carousel-o [options]="chipsCarouselOptions" (dragging)="onDragging($event)">
                                        <ng-container *ngFor="let item of message.items">
                                            <ng-template carouselSlide>
                                                <button class="btn graphics_suggestion" (click)="!dragging && selectGraphicElement('value', item, message)">{{ item.title }}</button>
                                            </ng-template>
                                        </ng-container>
                                    </owl-carousel-o>
                                </ng-container>
                                <ng-container *ngSwitchCase="'uploadFiles'">
                                    <div class="file_graphics">
                                        <div *ngFor="let file of message.items[0].files; let i = index" class="mb-2">
                                            <div class="input-group graphics_file">
                                                <input type="file" class="form-control" id="file{{ i }}" [accept]="file.extensionTypes.join(',')" name="file{{ i }}" (change)="uploadFile($event.target, message.items[0], i)" />
                                                <label class="form-label" [ngClass]="{ uploaded: file.uploaded, disable_upload: botSession.dialog.length !== interactionIndex + 1, 'border-danger': file.error }" for="file{{ i }}">
                                                    <i *ngIf="!(file.progress || file.uploaded) && !file.error" class="far fa-paperclip me-1" aria-hidden="true"></i>
                                                    <i *ngIf="file.error" class="text-danger far fa-exclamation-circle me-1" aria-hidden="true"></i>
                                                    {{ file.title }}
                                                </label>
                                                <div *ngIf="file.progress" class="file_progress" [ngStyle]="{ width: file.progress + '%' }"></div>
                                            </div>

                                            <small class="d-block text-danger" *ngIf="file.error; else hintTemplate">
                                                {{ file.error }}
                                            </small>

                                            <ng-template #hintTemplate>
                                                <small *ngIf="file.maxSize && !file.uploaded" class="d-block text-muted"> Max size {{ file.maxSize }}MB</small>
                                            </ng-template>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="'card'">
                                    <owl-carousel-o [options]="cardCarouselOptions">
                                        <ng-container *ngFor="let item of message.items">
                                            <ng-template carouselSlide [width]="225">
                                                <article class="graphics_card">
                                                    <figure class="card_image" *ngIf="item.image">
                                                        <img [src]="item.image" alt="{{ item.alt_image }}" />
                                                    </figure>
                                                    <div class="card_body">
                                                        <h5 class="body_title">{{ item.title }}</h5>
                                                        <h6 class="body_subtitle" *ngIf="item.subtitle">{{ item.subtitle }}</h6>
                                                        <p class="body_text" *ngIf="item.body">{{ item.body | slice: 0:75 }}<span *ngIf="item.body.length > 75">...</span></p>
                                                    </div>
                                                    <footer class="card_bottom" *ngIf="message.buttonType !== 'disabled'">
                                                        <button type="button" class="btn brand_default" (click)="selectGraphicElement(message.buttonType, item.button, message)">{{ item.button.value_name }}</button>
                                                    </footer>
                                                </article>
                                            </ng-template>
                                        </ng-container>
                                    </owl-carousel-o>
                                </ng-container>
                                <ng-container *ngSwitchCase="'list'">
                                    <ul class="list-unstyled graphics_list">
                                        <li *ngFor="let item of message | graphicPagination" [ngClass]="{ action_disabled: message.buttonType === 'disabled' }" (click)="selectGraphicElement(message.buttonType, item.button, message)">
                                            <article class="list_card" [ngClass]="{ 'h-auto': !(item.image || item.subtitle) }">
                                                <figure class="card_image" *ngIf="item.image">
                                                    <img [src]="item.image" alt="{{ item.alt_image }}" />
                                                </figure>
                                                <header class="card_head">
                                                    <h5 class="head_title">{{ item.title }}</h5>
                                                    <h6 class="text-muted head_subtitle" *ngIf="item.subtitle">{{ item.subtitle }}</h6>
                                                </header>
                                                <i class="far fa-chevron-right card_icon" aria-hidden="true"></i>
                                            </article>
                                        </li>
                                    </ul>
                                    <div class="next_button_container">
                                        <button class="btn brand_light" (click)="nextGraphicElements(interactionIndex, message)" *ngIf="lastInteraction && lastMessage && showNextButton(message)">{{ message.nextLabel }}</button>
                                    </div>
                                </ng-container>
                            </section>
                        </article>
                        <ng-container *ngIf="interaction.detectedInteraction">
                            <small class="text-secondary">{{ interaction.detectedInteraction }}</small>
                            <a href="javascript:void(0)" class="debug_info" (click)="viewDebug(interaction)"><i class="fal fa-info-circle fa-sm" aria-hidden="true"></i></a
                        ></ng-container>
                    </section>
                </div>
                <footer class="module_bottom">
                    <form #chatForm="ngForm" class="chat_form" (ngSubmit)="submitText()" autocomplete="off" novalidate>
                        <fieldset [disabled]="disableChat">
                            <legend class="d-none"></legend>
                            <div class="speech_field">
                                <input name="speechInput" maxlength="255" type="text" placeholder="Enter text..." class="form-control" [(ngModel)]="botSession.inputMessage" #speechInput="ngModel" />
                                <button class="send_message" type="submit" [disabled]="botSession.inputMessage?.length === 0">
                                    <span class="material-icons">send</span>
                                </button>
                            </div>
                        </fieldset>
                    </form>
                </footer>
            </section>
        </div>
    </div>
</div>
