import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@authentication';
import { JourneyRestrictionGuard, LoadingGuard, SessionGuard } from '@core';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { MainComponent } from './components/main/main.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  {
    path: '',
    component: MainComponent,
    canActivate: [SessionGuard],
    children: [
      {
        path: 'process-flow',
        canActivate: [LoadingGuard],
        loadChildren: () => import(`@process-flow`).then((m) => m.ProcessFlowModule),
      },
      { path: 'training', canActivate: [LoadingGuard], loadChildren: () => import(`@training`).then((m) => m.TrainingModule) },
      { path: 'admin', canActivate: [LoadingGuard], loadChildren: () => import(`@admin`).then((m) => m.AdminModule) },
      { path: 'analytics', canActivate: [LoadingGuard], loadChildren: () => import(`@analytics`).then((m) => m.AnalyticsModule) },
      {
        path: 'dashboard',
        component: DashboardComponent,
      },
      {
        path: 'channels',
        loadChildren: () => import(`@channels`).then((m) => m.ChannelsModule),
        canActivate: [LoadingGuard],
        data: {
          roleRestriction: ['channel.write', 'channel.read'],
        },
      },
      { path: 'monitoring', loadChildren: () => import(`@monitoring`).then((m) => m.MonitoringModule) }
    ],
  },
  { path: 'tone-of-voice', loadChildren: () => import('@tone-of-voice').then((m) => m.ToneOfVoiceModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
