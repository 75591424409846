// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from "@environment-token";

export const environment: Environment = {
  production: false,
  BASE_PATH: 'https://chatbotmalta-dev.elvoon.it',
  API: {
    orchestrator_analysis: '/analysis/api/v1',
    orchestrator_analysis_v2: '/analysis/api/v2',
    orchestrator_configurator: '/configurator/api/v2',
    orchestrator_rasa_model: '/rasa-model-server',
    orchestrator_chat: '/chat/api/v2',
    channel_whatsapp: '/bot/whatsapp',
    channel_vivocha: '/bot/vivocha',
    channel_liveperson: '/bot/liveperson',
    channel_timrcc: '/bot/timrcc',
    channel_facebook: '/bot/facebook',
    channel_google: '/bot/google',
    channel_teams: '/bot/teams',
    channel_telegram: '/bot/telegram'
  },
  websocket_path: 'wss://chatbotmalta-dev.elvoon.it/configurator/api/v2/stomp',
  AUTHENTICATION: {
    keycloak_issuer: 'https://chatbotmalta-dev.elvoon.it/access/realms/cartesio',
    keycloak_client: 'cartesio-frontend'
  }
};
