<div class="box_wrapper">
    <div class="dashboard_chunk">
        <h6 class="chunk_title">Conversations Training</h6>
        <div class="chunk_body">
            <div class="row">
                <div class="col-6">
                    <article class="stats_card">
                        <figure class="card_image"><i class="far fa-bookmark" aria-hidden="true"></i></figure>
                        <dl class="stats_list">
                            <dt>Assigned</dt>
                            <dd *ngIf="statistics$ | async as stats; else loading">{{ stats?.assigned | analyticalFormatter: { type: 'number', format: '1.0-2' } }}</dd>
                        </dl>
                    </article>
                    <article class="stats_card">
                        <figure class="card_image"><i class="far fa-eye" aria-hidden="true"></i></figure>
                        <dl class="stats_list">
                            <dt>Not analyzed</dt>
                            <dd *ngIf="statistics$ | async as stats; else loading">{{ stats?.notAnalyzed | analyticalFormatter: { type: 'number', format: '1.0-2' } }}</dd>
                        </dl>
                    </article>
                    <article class="stats_card">
                        <figure class="card_image"><i class="fas fa-exclamation-circle" aria-hidden="true"></i></figure>
                        <dl class="stats_list">
                            <dt>Fallbacks</dt>
                            <dd *ngIf="statistics$ | async as stats; else loading">{{ stats?.fallbacks | analyticalFormatter: { type: 'number', format: '1.0-2' } }}</dd>
                        </dl>
                    </article>
                </div>
            </div>
        </div>
        <footer class="chunk_bottom">
            <button type="button" class="btn brand_light" [routerLink]="['/training']">Train now</button>
        </footer>
    </div>
</div>

<ng-template #loading>
    <ng-template [spinnerLoader]="false" [size]="'sm'"></ng-template>
</ng-template>
