
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoleRestrictionGuard } from '@core';

import { AnalyticSessionsComponent } from './components/analytic-sessions/analytic-sessions.component';
import { AnalyticsComponent } from './components/analytics/analytics.component';
import { ConversationalHistoryComponent } from './components/conversational-history/conversational-history.component';

const routes: Routes = [
  {
    path: '',
    component: AnalyticsComponent,
    canActivate: [RoleRestrictionGuard],
    data: {
      roleRestriction: ['analytics.view']
    },
    children: [
      { path: '', redirectTo: 'sessions', pathMatch: 'full' },
      {
        path: 'sessions',
        component: AnalyticSessionsComponent
      },
      {
        path: 'conversational-history',
        component: ConversationalHistoryComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AnalyticsRoutingModule {}
