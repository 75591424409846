import { TestBed } from '@angular/core/testing';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { asyncScheduler, of, scheduled } from 'rxjs';
import { NotificationService } from '../services/notification.service';
import { EmptyComponent } from '../mocks/components.mock';

export const TEST_NOTIFICATION_SERVICE: Partial<NotificationService> = {
    openModal: () => {
        const modalService: NgbModal = TestBed.inject(NgbModal);
        modalService.open(EmptyComponent);

        return of(true);
    },
    showToast: () => null,
    removeToast: () => null,
    showModalSpinner: () => null,
    hideModalSpinner: () => null
};
