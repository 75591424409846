
<div class="row section_dashboard">
    <div class="col-3">
        <overview-card></overview-card>
    </div>
    <div class="col-9">
        <sessions-card></sessions-card>
    </div>
</div>

<div class="row section_dashboard">
    <div class="col-4">
        <analytics-card></analytics-card>
    </div>
    <div class="col-4">
        <training-card></training-card>
    </div>
    <div class="col-4">
        <monitoring-card></monitoring-card>
    </div>
</div>

<div class="row section_dashboard">
    <div class="col-4">
        <tone-of-voice-card></tone-of-voice-card>
    </div>
</div>
