import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AuthenticationService } from '@authentication';
import { NotificationService } from '@common-ui';
import { Account, JourneyService, UserAccountService } from '@core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService, UserSettingsModalComponent } from '@shared';


@Component({
  selector: 'console-header',
  templateUrl: './console-header.component.html',
  styleUrls: ['./console-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsoleHeaderComponent implements OnInit, OnDestroy {
  @Output() switchChat = new EventEmitter<any>();
  @Input() openChat: boolean;
  subscriptions: object = {};

  stretchMenu = false;

  get userAccounts(): Account[] {
    return this.userAccountService.userAccounts;
  }

  get user() {
    return this.userAccountService.user;
  }
  get journeys() {
    return this.journeyService.journeyList;
  }

  get activeJourney() {
    return this.journeyService.journey;
  }

  constructor(
    private userAccountService: UserAccountService,
    private modalService: NgbModal,
    private journeyService: JourneyService,
    private commonService: CommonService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.subscriptions['ToggleMenu'] = this.commonService.onStretchMenu().subscribe((stretchStatus: boolean) => {
      this.stretchMenu = stretchStatus;
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  switchAccount({ id }) {
    if (id === this.userAccountService.user.accountId) return;

    this.subscriptions['SetAccount'] = this.userAccountService.changeAccountUser({ id }).subscribe();
  }

  switchJourney(journey) {
    if (journey.id === this.activeJourney.id) return;
    this.journeyService.setSessionJourney(journey.id);
  }

  toggleMenu(stretchStatus: boolean) {
    this.commonService.switchMenu(!stretchStatus);
  }

  toggleChat(openStatus: boolean) {
    if (!this.journeyService.isProductConfigured([{ type: 'RS' }])) {
      this.notificationService.openModal({
        title: 'Test chat',
        message: 'To use the test chat you need to configure the NLU connector in the process flow section.',
        choice: 'none',
        type: 'warning',
      });
      return;
    }

    this.openChat = !openStatus;
    this.switchChat.emit(this.openChat);
  }

  logout() {
    this.authenticationService.logout();
  }

  modalSettings() {
    this.modalService.open(UserSettingsModalComponent, {
      size: 'xl',
    });
  }

}
