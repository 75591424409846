
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { RxStompService } from '@authentication';
import { JourneyService } from '@core';
import { CommonService, Pagination, ExportLogsModalComponent } from '@shared';
import { MonitoringService } from '../../services/monitoring.service';
import { NotificationService } from '@common-ui';

@Component({
  selector: 'logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
})
export class LogsComponent implements OnInit, OnDestroy {
  subscriptions: object = {};
  pagination: Pagination = new Pagination();
  connectors: Array<string> = [];
  severities: Array<string> = ['DEBUG', 'INFO', 'WARNING', 'ERROR'];

  filter: any = this.defaultFilter;

  logs: Array<any> = [];

  get defaultFilter() {
    return {
      connectors: [],
      severity: [],
      period: {
        startDate: new Date().setHours(0, 0, 0, 0),
        endDate: new Date().setHours(23, 59, 59, 0),
      },
    };
  }

  constructor(
    private journeyService: JourneyService,
    private monitoringService: MonitoringService,
    private notificationService: NotificationService,
    private modalService: NgbModal,
    private rxStompService: RxStompService,
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.subscriptions['Connectors'] = this.monitoringService.getLogsConnectors().subscribe((connectors: Array<any>) => {
      this.connectors = connectors;
    });

    this.subscriptions['Journey'] = this.journeyService.journey$.subscribe(() => {
      this.searchLogs(1);
    });
  }

  ngOnDestroy() {
    Object.keys(this.subscriptions).forEach((key: string) => {
      this.subscriptions[key].unsubscribe();
    });
  }

  searchLogs(pageSelected: number) {
    this.pagination.onSelectPage(pageSelected);

    const params = {
      connectors: this.filter.connectors,
      severity: this.filter.severity,
      startDate: this.filter.period.startDate,
      endDate: this.filter.period.endDate,
      textLog: this.filter.textLog,
    };

    this.subscriptions['LogsSubscription'] = this.monitoringService
      .getLogs(params, this.pagination.getPageIndex(), this.pagination.pageSize)
      .subscribe((response: any) => {
        this.logs = response;
      });
  }

  isSelected(itemName: string, type: 'connectors' | 'severity'): boolean {
    return this.filter[type].indexOf(itemName) !== -1;
  }

  selectItem(itemName: string, type: 'connectors' | 'severity') {
    if (this.isSelected(itemName, type)) {
      this.filter[type] = [...this.filter[type].filter((item) => item !== itemName)];
    } else {
      this.filter[type].push(itemName);
    }
  }

  selectAllItem(type: 'connectors' | 'severity') {
    const items = type === 'connectors' ? this.connectors : this.severities;

    if (this.filter[type].length === items.length) {
      this.filter[type] = [];
    } else {
      this.filter[type] = [...items];
    }
  }

  resetFilters() {
    this.filter = this.defaultFilter;

    this.searchLogs(1);
  }

  nextPage() {
    this.searchLogs(this.pagination.currentPage + 1);
  }

  exportLogs() {
    this.notificationService
      .openModal({
        title: 'Export Log',
        message:
          'You can export a maximum of 10000 logs in CSV format. When the export has finished, the download will start automatically and you will be notified.',
        type: 'warning',
        choice: 'multi',
      })
      .subscribe((confirm: boolean) => {
        if (confirm) {
          const dateStart = new Date(this.filter.period.startDate);
          const dateEnd = new Date(this.filter.period.endDate);
          const data = {
            startDate: new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate(), 0, 0, 0).getTime(),  
            endDate: new Date( dateEnd.getFullYear(), dateEnd.getMonth(), dateEnd.getDate(), 23, 59, 59).getTime(),
            journeyApiKey: this.journeyService.journey.apiKey,
          };
          this.rxStompService
            .exportLog(data)
            .pipe(first())
            .subscribe((response: any) => {
              this.notificationService.showToast('The export has been completed successfully, the automatic download will start shortly.', {
                type: 'success',
              });

              const byteString = atob(response.body);
              const arrayBuffer = new ArrayBuffer(byteString.length);
              const integersArray = new Uint8Array(arrayBuffer);

              for (let i = 0; i < byteString.length; i++) {
                integersArray[i] = byteString.charCodeAt(i);
              }

              this.commonService.downloadFile(arrayBuffer, 'blob', 'Logs.csv');
            });
        }
      });
  }

  exportModal() {
    const modalRef = this.modalService.open(ExportLogsModalComponent);

    modalRef.componentInstance.exportFilter = {
      ...this.filter,
      ...this.filter.period,
    };
  }
}
